import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { globalHistory } from '@reach/router'

import { useTranslation } from 'react-i18next'

import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import NewsArticle from "@components/blocks/news/Article"

import css from "@css/components/blocks/common/Page.module.styl"

export default function Article({data})
{
	const {t} = useTranslation()
	const { wordpressPost } = data
	const { title, excerpt, categories, path, featured_media} = wordpressPost
	console.log(data)
	return (
		<React.Fragment>
			<SEO
				title={`${title} | NEWS`}
				description={excerpt}
				pathname={path}
				image={featured_media && featured_media.localFile.publicURL}
				isArticle
			/>
			<div className={css.container}>
				<PageHeader l1="NEWS" l2={t('news__headerTitle2')}/>
				<div className={css.content}>
					<NewsArticle {...data.wordpressPost}/>
				</div>
			</div>
			<FooterSns/>
			<Footer/>
		</React.Fragment>
	)
}

export const pageQuery = graphql`
	fragment PostListFields on wordpress__POST {
		id
		slug
		content
		date(formatString: "YYYY-MM-DD")
		title
		excerpt
		path
		categories {
			name
			slug
		}
		featured_media {
			localFile {
			    childImageSharp {
			        fluid(maxWidth: 640) {
			            ...GatsbyImageSharpFluid
			        }
			    }
			    publicURL
			}
		}
		status
	}

	query BlogPostByID($id: String!) {

		wordpressPost(id: { eq: $id }) {
			id
			title
			slug
			content
			date(formatString: "YYYY-MM-DD")
			excerpt
			path
			featured_media {
				localFile {
				    childImageSharp {
				        fluid(maxWidth: 860, maxHeight: 480) {
				            ...GatsbyImageSharpFluid
				        }
				    }
				    publicURL
				}
			}
			categories {
				name
				slug
			}
		}
	}
`
